/**
 * Created by stapletont on 10/02/2015.
 */

define('polyfill', ['es5Shim'], function() {

  if (!('baseURI' in Node.prototype)) {
    Object.defineProperty(Node.prototype, 'baseURI', {
      get: function() {
        var base = (this.ownerDocument || this).querySelector('base');
        return (base || window.location).href;
      },
    });
  }

  // From: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('remove')) {
        return;
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          if (this.parentNode !== null)
            this.parentNode.removeChild(this);
        }
      });
    });
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

  // This is not even standard!!
  if (!Array.prototype.unique) {
    Array.prototype.unique = function() {
      var unique = [];
      for (var i = 0; i < this.length; i++) {
        if (unique.indexOf(this[i]) === -1) {
          unique.push(this[i]);
        }
      }
      return unique;
    };
  }

  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      var el = this;

      do {
        if (Element.prototype.matches.call(el, s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

});
